import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NavComponent } from './core/nav/nav.component';
import { IsLoggedInGuard } from './guards/IsLoggedInGuard';
import { IsNotLoggedInGuard } from './guards/IsNotLoggedInGuard';
import { RoleGuard } from './guards/RoleGuard';
import { PortalUserRoleType } from './providers/eightysix-api/data-service.generated';

const routes: Routes = [
  { path: '', redirectTo: '/home/dashboard', pathMatch: 'full' } /* starting page */,
  {
    path: 'home/dashboard',
    canActivate: [IsLoggedInGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home-router/home-router.module').then((m) => m.HomeRouterModule),
      },
    ],
  },
  {
    path: 'auth',
    canActivate: [IsNotLoggedInGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountModule),
      },
    ],
  },
  {
    component: NavComponent,
    path: 'primary',
    data: { role: PortalUserRoleType.Primary },
    canActivate: [IsLoggedInGuard, RoleGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/primary/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'portaluser',
        loadChildren: () =>
          import('./pages/primary/settings/portaluser/portaluser.module').then((m) => m.PortalUserModule),
      },
      {
        path: 'fibreoperator',
        loadChildren: () =>
          import('./pages/primary/fibreoperator/fibreoperator.module').then((m) => m.FibreOperatorModule),
      },
      {
        path: 'isp',
        loadChildren: () => import('./pages/primary/isp/isp.module').then((m) => m.IspModule),
      },
      {
        path: 'leads',
        loadChildren: () => import('./pages/primary/leads/leads.module').then((m) => m.LeadsModule),
      },
      {
        path: 'partial-leads',
        loadChildren: () =>
          import('./pages/primary/partial-leads/partial-leads.module').then((m) => m.PartialLeadsModule),
      },
      {
        path: 'portaluser',
        loadChildren: () =>
          import('./pages/primary/settings/portaluser/portaluser.module').then((m) => m.PortalUserModule),
      },
      {
        path: 'techsettings',
        loadChildren: () =>
          import('./pages/primary/settings/tech-settings/tech-settings.module').then((m) => m.TechSettingsModule),
      },

      {
        path: 'report',
        loadChildren: () => import('./pages/primary/report/report.module').then((m) => m.ReportPageModule),
      },
      {
        path: 'imagegallery',
        loadChildren: () =>
          import('./pages/primary/imagegallery/imagegallery.module').then((m) => m.ImageGalleryModule),
      },
      {
        path: 'networktype',
        loadChildren: () => import('./pages/primary/networktype/networktype.module').then((m) => m.NetworkTypeModule),
      },
    ],
  },
  { path: '**', redirectTo: '/home/dashboard' } /* wildcard */,
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  relativeLinkResolution: 'legacy',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
