export class CommonUtils {
  static firstOrDefault<T>(arr: T[]): T {
    if (!arr) {
      return undefined;
    }

    if (arr.length <= 0) {
      return undefined;
    }

    return arr[0];
  }

  static lastOrDefault<T>(arr: T[]): T {
    if (!arr) {
      return undefined;
    }

    if (arr.length <= 0) {
      return undefined;
    }

    return arr[length - 1];
  }

  static isNullOrWhiteSpace(str: any): boolean {
    if (!str) {
      return true;
    } else if (typeof str === 'undefined') {
      return true;
    } else if (typeof str === 'undefined') {
      return true;
    } else if (typeof str === 'string') {
      return !/\S/.test(str);
    } else {
      return true;
    }
  }

  static isNullOrEmptyGuid(str: any): boolean {
    if (!str) {
      return true;
    } else if (typeof str === 'undefined') {
      return true;
    } else if (typeof str === 'undefined') {
      return true;
    } else if (typeof str === 'string') {
      return str === '00000000-0000-0000-0000-000000000000';
    } else {
      return true;
    }
  }

  static createGuid(): string {
    // tslint:disable-next-line: no-bitwise
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();
  }

  static isEmptyGuid(guid: string) {
    if (!guid) {
      return true;
    }

    guid = guid.trim().toLowerCase();
    return guid === '00000000000000000000000000000000' || guid === '00000000-0000-0000-0000-000000000000';
  }

  static isJSON(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static truncateString(s: string, length: number): string {
    let trimmedString = s.substr(0, length);
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));
    return trimmedString;
  }

  static fileNameFromUrl(url: string, extension: string = ''): string {
    // https://dev.86degrees.com/file/test/filename.txt
    // strip url parameters
    url = url.split('?')[0];
    // if extension is specified and does not exist, add it
    if (extension.length > 1 && url.indexOf(extension) < 0) {
      url += extension;
    }

    const s = url.split('/');
    return encodeURI(s[s.length - 1]);
  }

  static toTitleCase(str: string) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(' ');
  }

  /** Pad a number with zeros */
  static padLeft(nr: number, n: number, str = '0') {
    return Array(n - String(nr).length + 1).join(str || '0') + nr;
  }

  /** Return a random number between two values */
  static randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /** Deep copy an object to get a new reference. */
  static deepCopy<T = any>(object: T): T {
    if (object === undefined || object === null) {
      return object;
    }

    return JSON.parse(JSON.stringify(object)) as T;
  }

  /** Compare the JSON values of 2 objects to check equality. */
  static deepCompare(object1: any, object2: any): boolean {
    return JSON.stringify(object1) === JSON.stringify(object2);
  }

  /**
   * Copy each member from one object to another.
   * Non-recursive! One level only.
   * @param  deep - Use deep copy when copying members. Default true.
   */
  static memberCopy(from: any, to: any, deep = true) {
    if (!to) {
      to = {};
    }
    // tslint:disable-next-line: forin
    for (const k in from) {
      to[k] = deep ? CommonUtils.deepCopy(from[k]) : from[k];
    }
  }
  /**Get the difference between two objects, checking each field in base against new.
   */
  static objectDiff(baseObject: any, newObject: any): { property: string; baseValue: any; newValue: any }[] {
    const diff: {
      property: string;
      baseValue: any;
      newValue: any;
    }[] = [];
    if (!baseObject) {
      return diff;
    }
    for (const k in baseObject) {
      if (JSON.stringify(baseObject[k]) !== JSON.stringify(newObject[k])) {
        diff.push({
          property: k,
          baseValue: baseObject[k],
          newValue: newObject[k],
        });
      }
    }
    return diff;
  }

  /** Turn a number into a ZAR formatted currency string. */
  static convertNumberToPrice(
    n: any,
    vat: number = 0,
    spacing: boolean = true,
    decimals: boolean = true,
    currencyCode: string = 'R'
  ): string {
    if (vat > 0) {
      n = n * (1 + vat);
    } // include vat

    const c = 2;
    const d = '.';
    const t = ',';
    const s = n < 0 ? '-' : '';
    const i = parseInt((n = Math.abs(Number(n) || 0).toFixed(c)));
    let j = String(i).length;
    j = j > 3 ? j % 3 : 0;

    let value =
      s +
      (spacing ? `${currencyCode} ` : `${currencyCode}`) +
      (j ? String(i).substr(0, j) + t : '') +
      String(i)
        .substr(j)
        .replace(/(\d{3})(?=\d)/g, '$1' + t);
    if (decimals) {
      value += c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : '';
    }

    return value;
  }

  /** Safely open a link in a new tab. */
  static newTabLink(link: string) {
    window.open(link, '_blank', 'noopener,noreferrer');
  }

  static nameOf<T, V extends T[keyof T]>(f: (x: T) => V): valueOf<{ [K in keyof T]: T[K] extends V ? K : never }>;
  /**Get the string value of a given property name.
   * Used to allow compile time fields where string names are required
   */
  static nameOf(f: (x: any) => any): keyof any {
    const p = new Proxy(
      {},
      {
        get: (target, key) => key,
      }
    );
    return f(p);
  }

  static groupBy<T>(array: T[], fields: (item: T) => any[]): T[][] {
    const groups = {};
    array.forEach((o) => {
      const group = JSON.stringify(fields(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map((group) => {
      return groups[group];
    });
  }

  /**
   * Convert a string into an rgb color.
   * https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
   */
  static stringToColor(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      // tslint:disable-next-line: no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      // tslint:disable-next-line: no-bitwise
      const value = (hash >> (i * 8)) & 0xff;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  static roundNumber(num: number, dec: number): number {
    return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
  }
}
export type valueOf<T> = T[keyof T];
