import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Environment } from 'src/environments/environment';
import {
  API_BASE_URL,
  AuthService,
  DashboardService,
  ImageService,
  IspDashboardService,
  IspLeadService,
  IspService,
  LeadService,
  NetworkOperatorService,
  NetworkTypeService,
  PortalUserAccountService,
  ReportService,
  SearchService,
  TechSettingsService,
} from './data-service.generated';
import { RefreshTokenInterceptor } from './refresh-token-interceptor';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    // Shared Services
    AuthService,
    SearchService,
    // Primary Services
    PortalUserAccountService,
    NetworkOperatorService,
    IspService,
    ReportService,
    DashboardService,
    LeadService,
    ImageService,
    TechSettingsService,
    NetworkTypeService,
    // ISP Services
    IspDashboardService,
    IspLeadService,
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: API_BASE_URL, useValue: Environment.ApiServiceUrl },
  ],
})
export class DataServiceModule {}
