import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserAuthService } from '../providers/eightysix-api/user-auth-service';
import { CommonUtils } from '../utils/common-utils';

/**
 * Make sure that the user is logged in before allowing them access to a specified route.
 * Otherwise, route them to /login
 */
@Injectable({
  providedIn: 'root',
})
export class IsLoggedInGuard implements CanActivate {
  constructor(private router: Router, private auth: UserAuthService) {}
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // do we have a token? Otherwise we won't be logged in.
    // If the token has expired, the interceptor will pick it up and run the refresh.
    if (!CommonUtils.isNullOrWhiteSpace(this.auth.authToken)) {
      return true;
    }

    await this.auth.logout(false);
    await this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } });

    return false;
  }
}
