export enum RunningEnvironment {
  /** Running locally */
  Debug,
  /** Dev environment on server */
  Dev,
  /** Prod environment on server */
  Live,
}

// This is the base environment setup with default values set up for local debugging
// The production environment may override some of these values
export class EnvironmentBase {
  // this is used by Angular to determine whether it should run in prod mode
  public static get AngularProduction(): boolean {
    return false;
  }

  public static get ApiServiceUrl(): string {
    switch (this.CurrentRunningEnvironment) {
      case RunningEnvironment.Debug:
        return '';
      case RunningEnvironment.Dev:
        return '';
      case RunningEnvironment.Live:
        return '';
    }
  }

  public static get ApiKey(): string {
    return 'dc961fc9-5e7f-4c61-9a93-a05bfd2ff463';
  }

  public static get CurrentRunningEnvironment(): RunningEnvironment {
    if (window.location.href.includes('localhost')) {
      // local url
      return RunningEnvironment.Debug;
    } else if (window.location.href.includes('dev.')) {
      // running as dev on the server
      return RunningEnvironment.Dev;
    } else {
      // running as prod on the server
      return RunningEnvironment.Live;
    }
  }

  public static get showDebugBanner() {
    return this.CurrentRunningEnvironment !== RunningEnvironment.Live;
  }

  private static deviceId: string;
  public static get DeviceIdentifier() {
    return this.deviceId;
  }
  public static set DeviceIdentifier(value: string) {
    this.deviceId = value;
  }
}
