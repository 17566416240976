import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { CommonUtils } from 'src/app/utils/common-utils';
import { RefreshTokenResponse } from './data-service.generated';
import { UserAuthService } from './user-auth-service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(private authService: UserAuthService) {}

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
    return next.handle(this.addTokenToRequest(request, this.authService.authToken)).pipe(
      catchError((err) => {
        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (
          request.url.toLowerCase().includes('refreshtoken') ||
          request.url.toLowerCase().includes('login') ||
          request.url.toLowerCase().includes('logout')
        ) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user and to redirect to login page
          if (request.url.toLowerCase().includes('refreshtoken')) {
            this.authService.logout();
          }

          return throwError(err);
        }

        if (err instanceof HttpErrorResponse) {
          switch ((err as HttpErrorResponse).status) {
            case 401:
              return this.handle401Error(request, next, err);
            // case 400:
            //    return <any>this.authService.logout();
          }
          return throwError(err);
        } else {
          return throwError(err);
        }
      })
    );
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token && !CommonUtils.isNullOrWhiteSpace(token)) {
      return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }

    return request;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, err: HttpErrorResponse) {
    // do we actually have a token to try refresh?
    if (!this.authService.authToken || CommonUtils.isNullOrWhiteSpace(this.authService.authToken)) {
      return throwError(err);
    }

    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authService.refreshAuthToken().pipe(
        switchMap((response: RefreshTokenResponse) => {
          if (response && response.wasSuccessful) {
            this.tokenSubject.next(response.token);
            return next.handle(this.addTokenToRequest(request, response.token));
          }

          return from(this.authService.logout());
        }),
        catchError((catchErr) => {
          return from(this.authService.logout());
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      this.isRefreshingToken = false;

      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addTokenToRequest(request, token));
        })
      );
    }
  }
}
