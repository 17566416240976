import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './core/nav/nav.component';
import { DataServiceModule } from './providers/eightysix-api/data-service.module';
import { SetupService } from './providers/setup-service';

registerLocaleData(en);

export function init_app(setupService: SetupService) {
  return () => setupService.initApp();
}

@NgModule({
  declarations: [AppComponent, NavComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NzLayoutModule,
    NzBreadCrumbModule,
    NzMenuModule,
    NzPageHeaderModule,
    NzIconModule,
    NzNotificationModule,
    NzBackTopModule,
    NzModalModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DataServiceModule,
    DragDropModule,
    ScrollingModule,
    NzBadgeModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [SetupService], multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
