import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private crumbs: BreadcrumbData[] = [];
  breadcrumbChange: BehaviorSubject<BreadcrumbData[]> = new BehaviorSubject([]);

  constructor(private activatedRoute: ActivatedRoute) {}

  /** Add a single breadcrumb with data from the current route */
  addCrumb() {
    this.crumbs = [];
    const crumb = new BreadcrumbData();
    crumb.title = this.findCurrentTitle();
    this.crumbs.push(crumb);

    this.breadcrumbChange.next(this.crumbs);
  }

  addCrumbs(current: string | null, ...routes: BreadcrumbData[]) {
    this.crumbs = routes;

    if (current) {
      const crumb = new BreadcrumbData();
      crumb.title = current;
      this.crumbs.push(crumb);
    }

    this.breadcrumbChange.next(this.crumbs);
  }

  private findCurrentTitle(): string {
    let child = this.activatedRoute.firstChild;
    let title = '';
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && child.snapshot.data['title']) {
        title = child.snapshot.data['title'];
        break;
      } else {
        title = '';
        break;
      }
    }

    return title;
  }
}

export class BreadcrumbData {
  constructor(title = '', route = '') {
    this.title = title;
    this.route = route;
  }
  route: string;
  title: string;
}
