import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { AuthService } from 'src/app/providers/eightysix-api/data-service.generated';
import { UserAuthService } from 'src/app/providers/eightysix-api/user-auth-service';
import { BreadcrumbData, BreadcrumbService } from 'src/app/utils/breadcrumbs';
import { CommonUtils } from 'src/app/utils/common-utils';
import { PopupHelper } from 'src/app/utils/popup-helper';
import { TimerHelper } from 'src/app/utils/timer-helper';
import { Environment } from 'src/environments/environment';
import { VERSION } from 'src/environments/version';
import { MenuElement, MenuItems } from '../menu-items';

@Component({
  selector: 'app-nav-layout',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
  today: number = Date.now();
  triggerTemplate: TemplateRef<void> | null = null;
  openProfile = false;

  isCollapsed: boolean;
  currentUrl = '';
  menus: MenuElement[] = [];

  alertTimer: number;
  showDebugBanner: boolean;
  version = VERSION;

  customBreadcrumbTemplate: TemplateRef<any>;
  crumbs: BreadcrumbData[] = [];

  @ViewChild('trigger', { static: true }) customTrigger: TemplateRef<void>;

  constructor(
    public userAuthService: UserAuthService,
    public authService: AuthService,
    public menu: MenuItems,
    private popup: PopupHelper,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private timer: TimerHelper
  ) {}

  ngOnInit(): void {
    this.menus = this.menu.menu;
    this.showDebugBanner = Environment.showDebugBanner;

    // refresh menu
    this.currentUrl = '';
    this.updateMenuItems(this.parseUrl(this.router.url));

    // this.updateAlerts();
    // this.alertTimer = window.setInterval(() => this.updateAlerts(), 60000); // every minute

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.updateMenuItems(this.parseUrl(val.url));

        // clear the custom breadcrumb template for the next page
        this.setBreadcrumbTemplate(null);
      }
    });

    this.breadcrumbService.breadcrumbChange.pipe(delay(0)).subscribe((data: BreadcrumbData[]) => {
      this.crumbs = data;
    });
  }

  ngOnDestroy() {
    this.timer.clearInterval(this.alertTimer);
  }

  // private async updateAlerts() {
  //   let moderationCount = await this.alertService.getModerationCounter().toPromise();
  //   this.updateBadge(this.menus, '/investor/moderation/list', moderationCount);
  // }

  private updateBadge(item: MenuElement[], route: string, count: number): number {
    for (const menu of item) {
      if (menu.links) {
        for (const l of menu.links) {
          if (l === route) {
            menu.badge = count;
            return menu.badge;
          }
        }
      }

      if (menu.children && menu.children.length > 0) {
        const c = this.updateBadge(menu.children, route, count);
        if (c >= 0) {
          menu.badge = c; // roll up to parent
          return menu.badge;
        }
      }
    }

    return -1;
  }

  parseUrl(url: string) {
    const urlTree = this.router.parseUrl(url);
    const urlWithoutParams = urlTree.root.children['primary'].segments.map((it) => it.path).join('/');
    return '/' + urlWithoutParams;
  }

  updateMenuItems(route: string) {
    if (route !== this.currentUrl) {
      // is there at least one matching route?
      const match = this.isMatchingRoute(this.menus, route);
      if (match || CommonUtils.isNullOrWhiteSpace(this.currentUrl)) {
        this.activateMatchingRoute(this.menus, route);
      }
    }
    this.currentUrl = route;
  }

  isMatchingRoute(items: MenuElement[], matching: string): boolean {
    for (const item of items) {
      if (item.links && item.links.includes(matching)) {
        return true;
      }

      if (item.children) {
        const match = this.isMatchingRoute(item.children, matching);
        if (match) {
          return true;
        }
      }
    }

    return false;
  }

  activateMatchingRoute(items: MenuElement[], route: string): boolean {
    let active = false;
    for (const item of items) {
      let subMenuActive = false;
      if (item.children) {
        subMenuActive = this.activateMatchingRoute(item.children, route);
        item.open = subMenuActive; // one of the sub items are activated

        if (subMenuActive && !active) {
          active = true;
        }
      }

      item.selected = (item.links && item.links.some((el) => el === route)) || subMenuActive;
      if (item.selected && !active) {
        active = true;
      }
    }

    return active;
  }

  /** custom trigger can be TemplateRef */
  changeTrigger(): void {
    this.triggerTemplate = this.customTrigger;
  }

  async logout() {
    this.popup.showInfo('Logging you out...');
    await this.userAuthService.logout();
  }

  setBreadcrumbTemplate(template: TemplateRef<any>) {
    this.timer.setTimeout(() => {
      this.customBreadcrumbTemplate = template;
    }, 0);
  }
}
