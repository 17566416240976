import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class PopupHelper {
  constructor(private notification: NzNotificationService, private modalService: NzModalService) {}

  showAlert(message: string, title: string | null = 'Alert') {
    // this.toastr.error(message, title, { progressBar: true });

    this.notification.error(title, message);
  }

  showSuccess(message: string, title: string | null = 'Success') {
    // this.toastr.success(message, title, { progressBar: true });
    this.notification.success(title, message);
  }

  showInfo(message: string, title: string | null = 'Info') {
    // this.toastr.info(message, title, { progressBar: true });
    this.notification.info(title, message);
  }

  showWarning(message: string, title: string | null = 'Warning') {
    // this.toastr.info(message, title, { progressBar: true });
    this.notification.warning(title, message);
  }

  showConfirm(title: string, description: string): Promise<boolean> {
    const promise = new Promise<boolean>((resolve) => {
      const modal = this.modalService.confirm({
        nzTitle: title,
        nzContent: description,
        nzOkText: 'Yes',
        nzCancelText: 'No',
        nzOnOk: () => resolve(true),
        nzOnCancel: () => resolve(false),
      });
    });

    return promise;
  }

  showDeleteConfirm(
    title: string = 'Are you sure?',
    description: string = 'Do you really want to delete this item? This process cannot be undone.'
  ): Promise<boolean> {
    const promise = new Promise<boolean>((resolve) => {
      const modal = this.modalService.confirm({
        nzTitle: title,
        nzContent: description,
        nzOkText: 'Yes',
        nzOkDanger: true,
        nzCancelText: 'No',
        nzOnOk: () => resolve(true),
        nzOnCancel: () => resolve(false),
        nzIconType: 'exclamation-circle',
      });
    });

    return promise;
  }
}
