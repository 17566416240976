import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserAuthService } from '../providers/eightysix-api/user-auth-service';
import { CommonUtils } from '../utils/common-utils';

/**
 * Make sure that a user IS NOT logged in in order to access this route.
 * Otherwise, redirect to /dashboard
 */
@Injectable({
  providedIn: 'root',
})
export class IsNotLoggedInGuard implements CanActivate {
  constructor(private router: Router, private auth: UserAuthService) {}
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const token = this.auth.authToken;
    if (!CommonUtils.isNullOrWhiteSpace(token)) {
      this.router.navigate(['/home/dashboard']);
      return false;
    }

    return true;
  }
}
