import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TimerHelper {
  constructor(private zone: NgZone) {}

  public setInterval(cb: () => void, interval: number): number {
    let id = 0;
    this.zone.runOutsideAngular(() => {
      id = window.setInterval(() => {
        this.zone.run(() => {
          cb();
        });
      }, interval);
    });

    return id;
  }

  public clearInterval(id: number): void {
    window.clearInterval(id);
  }

  public setTimeout(cb: () => void, timeout: number): number {
    let id = 0;
    this.zone.runOutsideAngular(() => {
      id = window.setTimeout(() => {
        this.zone.run(() => {
          cb();
        });
      }, timeout);
    });

    return id;
  }

  public clearTimeout(id: number): void {
    window.clearTimeout(id);
  }
}
