import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  PermissionActionType,
  PermissionSectionType,
  PortalUserRoleType,
} from '../providers/eightysix-api/data-service.generated';
import { UserAuthService } from '../providers/eightysix-api/user-auth-service';
import { AppEvents } from '../utils/app-events';

@Injectable({
  providedIn: 'root',
})
export class MenuItems {
  private permissionSubscription: Subscription;
  constructor(private userAuthService: UserAuthService, private ev: AppEvents) {
    // subscribe to permission change events
    this.permissionSubscription = this.ev.permissionChange.subscribe(() => {
      this.refreshPermissions();
    });
  }

  public get menu() {
    this.setPagePermissions(this.menuElements);
    return this.menuElements;
  }

  private menuElements: MenuElement[] = [
    /// PRIMARY ITEMS
    {
      title: 'Dashboard',
      level: 1,
      links: ['/primary/home/dashboard', '/primary/home/partialleadsdashboard'],
      icon: 'fas fa-home fa-fw',
      role: PortalUserRoleType.Primary,
      children: [
        {
          title: 'Leads Dashboard',
          level: 2,
          links: ['/primary/home/dashboard'],
          role: PortalUserRoleType.Primary,
          sectionType: PermissionSectionType.LeadsDashboard,
          actionTypes: [PermissionActionType.View],
        },
        {
          title: 'Partial Leads Dashboard',
          level: 2,
          links: ['/primary/home/partialleadsdashboard'],
          role: PortalUserRoleType.Primary,
          sectionType: PermissionSectionType.PartialLeadsDashboard,
          actionTypes: [PermissionActionType.View],
        },
      ],
    },
    {
      title: 'Leads',
      level: 1,
      links: ['/primary/leads/list', '/primary/partial-leads/list'],
      icon: 'fas fa-award fa-fw',
      role: PortalUserRoleType.Primary,
      children: [
        {
          title: 'Leads',
          level: 2,
          links: ['/primary/leads/list', '/primary/leads/capture'],
          role: PortalUserRoleType.Primary,
          sectionType: PermissionSectionType.Leads,
          actionTypes: [PermissionActionType.View, PermissionActionType.Edit],
        },
        {
          title: 'Partial Leads',
          level: 2,
          links: ['/primary/partial-leads/list'],
          role: PortalUserRoleType.Primary,
          sectionType: PermissionSectionType.PartialLeads,
          actionTypes: [PermissionActionType.View, PermissionActionType.Edit],
        },
      ],
    },
    {
      title: 'Network Operators',
      level: 1,
      links: ['/primary/fibreoperator/list', '/primary/fibreoperator/capture'],
      icon: 'fas fa-network-wired fa-fw',
      role: PortalUserRoleType.Primary,
      sectionType: PermissionSectionType.NetworkOperators,
      actionTypes: [PermissionActionType.View, PermissionActionType.Edit],
    },
    {
      title: 'Network Types',
      level: 1,
      links: ['/primary/networktype/list', '/primary/networktype/capture'],
      icon: 'fas fa-project-diagram fa-fw',
      role: PortalUserRoleType.Primary,
      sectionType: PermissionSectionType.NetworkTypes,
      actionTypes: [PermissionActionType.View, PermissionActionType.Edit],
    },
    {
      title: 'Packages',
      level: 1,
      links: ['/primary/isp/list', '/primary/isp/capture'],
      icon: 'fas fa-wifi fa-fw',
      role: PortalUserRoleType.Primary,
      sectionType: PermissionSectionType.Isps,
      actionTypes: [PermissionActionType.View, PermissionActionType.Edit],
    },
    // {
    //   title: 'Affiliates',
    //   level: 1,
    //   icon: 'fas fa-search-dollar fa-fw',
    //   role: PortalUserRoleType.Primary,
    //   children: [
    //     {
    //       title: 'Standard Affiliates',
    //       level: 2,
    //       links: ['/primary/affiliate/list', '/primary/affiliate/capture'],
    //       role: PortalUserRoleType.Primary,
    //       sectionType: PermissionSectionType.Affiliates,
    //       actionTypes: [PermissionActionType.View, PermissionActionType.Edit],
    //     },
    //     {
    //       title: 'Agent Affiliates',
    //       level: 2,
    //       links: ['/primary/affiliate/agent/list'],
    //       role: PortalUserRoleType.Primary,
    //       sectionType: PermissionSectionType.Affiliates,
    //       actionTypes: [PermissionActionType.View, PermissionActionType.Edit],
    //     },
    //   ],
    // },
    {
      title: 'Image Gallery',
      level: 1,
      links: ['/primary/imagegallery/list'],
      icon: 'fas fa-images fa-fw',
      role: PortalUserRoleType.Primary,
      sectionType: PermissionSectionType.ImageGallery,
      actionTypes: [PermissionActionType.View, PermissionActionType.Edit],
    },
    {
      title: 'Reports',
      level: 1,
      links: ['/primary/report'],
      icon: 'fas fa-file-alt fa-fw',
      role: PortalUserRoleType.Primary,
      sectionType: PermissionSectionType.Reports,
      actionTypes: [PermissionActionType.View],
    },
    {
      title: 'Settings',
      level: 1,
      icon: 'fas fa-cogs fa-fw',
      role: PortalUserRoleType.Primary,
      children: [
        {
          title: 'Portal Users',
          level: 2,
          links: ['/primary/portaluser/list', '/primary/portaluser/capture', '/primary/portaluser/new'],
          sectionType: PermissionSectionType.PortalUsers,
          actionTypes: [PermissionActionType.View, PermissionActionType.Edit],
          role: PortalUserRoleType.Primary,
        },
        {
          title: 'Tech Settings',
          level: 2,
          links: ['/primary/techsettings/capture'],
          role: PortalUserRoleType.Primary,
          adminAccess: true,
        },
      ],
    },
  ];

  private refreshPermissions() {
    this.setPagePermissions(this.menuElements);
  }

  private setPagePermissions(items: MenuElement[]) {
    for (const item of items) {
      if (item.visible === undefined) {
        item.visible = true;
      }

      if (item.children) {
        if (item.children) {
          this.setPagePermissions(item.children);
        }
        item.visible = item.children.some((x) => x.visible);
      } else {
        item.visible =
          (item.role === undefined || this.userAuthService.hasRole(item.role)) &&
          ((item.sectionType === undefined && item.actionTypes === undefined) ||
            this.userAuthService.hasAnySectionPermission(item.sectionType, item.actionTypes));
      }

      // (item.pageAccessType && this.userAuthService.hasPageAccess(item.pageAccessType)) || !item.pageAccessType;

      if (item.adminAccess) {
        item.visible = this.userAuthService.isAdministrator;
      }
    }
  }
}

export class MenuElement {
  title: string;
  level: number;
  icon?: string;
  open?: boolean | null = false;
  links?: string[];
  selected?: boolean | null = false;
  disabled?: boolean | null = false;
  children?: MenuElement[] | null = null;
  adminAccess?: boolean | null = false;
  visible?: boolean | null = true;
  badge?: number | null = 0;

  // roles and permissions
  actionTypes?: PermissionActionType[] = null;
  sectionType?: PermissionSectionType = null;
  role?: PortalUserRoleType = null;
}
