import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppEvents {
  // permissions
  public permissionChange: Subject<any> = new Subject();
}
