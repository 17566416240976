/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';
import { EnvironmentBase } from './environment.base';

export class Environment extends EnvironmentBase {
  // this is used by Angular to determine whether it should run in prod mode
  public static get AngularProduction(): boolean {
    return false;
  }
}

export const environment = {
  production: false,
};
