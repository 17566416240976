import { Injectable } from '@angular/core';
import { clear as idbClear, get as idbGet, set as idbSet } from 'idb-keyval';
import { CommonUtils } from './common-utils';

@Injectable({
  providedIn: 'root',
})
export class AppStorage {
  private get<T>(key: string): Promise<T> {
    try {
      return idbGet<T>(key);
    } catch {
      return this.getNonIdbStorage<T>(key, false);
    }
  }

  private set(key: string, value: any) {
    try {
      return idbSet(key, value);
    } catch {
      return this.setNonIdbStorage(key, value, false);
    }
  }

  /// Set localStorage or sessionStorage through a promise.
  private setNonIdbStorage(key: string, value: any, session: boolean): Promise<void> {
    return Promise.resolve().then(() => {
      if (!session) {
        localStorage.setItem(key, JSON.stringify(value));
      } else {
        sessionStorage.setItem(key, JSON.stringify(value));
      }
    });
  }

  /// Get a value from localStorage or sessionStorage through a promise.
  private getNonIdbStorage<T>(key: string, session: boolean): Promise<T> {
    return Promise.resolve().then(() => {
      let value: any;
      if (!session) {
        value = localStorage.getItem(key);
      } else {
        value = sessionStorage.getItem(key);
      }

      if (!value) {
        return null;
      }

      try {
        return JSON.parse(value) as T;
      } catch {
        return null; // cannot parse the JSON properly (probably old data)
      }
    });
  }

  public clear(): Promise<void> {
    try {
      return idbClear();
    } catch {
      return Promise.resolve().then(() => {
        localStorage.clear();
        sessionStorage.clear();
      });
    }
  }

  //////////
  public async getDeviceIdentifier() {
    let item = await this.get<string>('DeviceIdentifier');
    if (!item) {
      item = CommonUtils.createGuid();
      await this.set('DeviceIdentifier', item);
    }
    return item;
  }

  //////////
  public async getAppUrl() {
    return this.get<string>('AppUrl');
  }

  public setAppUrl(value: string) {
    return this.set('AppUrl', value);
  }

  //////////
  public async getAuthToken() {
    if (await this.getRememberMe()) {
      return this.get<string>('AuthToken');
    } else {
      return this.getNonIdbStorage<string>('AuthToken', true);
    }
  }

  public async setAuthToken(value: string) {
    if (await this.getRememberMe()) {
      return this.set('AuthToken', value);
    } else {
      return this.setNonIdbStorage('AuthToken', value, true);
    }
  }

  //////////
  public async getRefreshToken() {
    if (await this.getRememberMe()) {
      return this.get<string>('RefreshToken');
    } else {
      return this.getNonIdbStorage<string>('RefreshToken', true);
    }
  }

  public async setRefreshToken(value: string) {
    if (await this.getRememberMe()) {
      return this.set('RefreshToken', value);
    } else {
      return this.setNonIdbStorage('RefreshToken', value, true);
    }
  }

  //////////
  public async getRememberMe() {
    const val = await this.get<boolean>('RememberMe');
    if (!val) {
      return false;
    }

    return val;
  }

  public async setRememberMe(value: boolean) {
    return this.set('RememberMe', value);
  }

  //////////
  public async setIsAdministrator(value: boolean) {
    return this.set('IsAdministrator', value);
  }

  public async getIsAdministrator() {
    const val = await this.get<boolean>('IsAdministrator');
    if (!val) {
      return false;
    }

    return val;
  }

  //////////
  public async getName() {
    const val = await this.get<string>('Name');
    if (!val) {
      return '';
    }

    return val;
  }

  public setName(value: string) {
    return this.set('Name', value);
  }
}
