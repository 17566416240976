import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {
  PortalActionType,
  PortalSectionType,
  PortalUserRoleType,
} from '../providers/eightysix-api/data-service.generated';
import { UserAuthService } from '../providers/eightysix-api/user-auth-service';

/**
 * Make sure that the user is logged in before allowing them access to a specified route.
 * Otherwise, route them to /login
 */
@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private auth: UserAuthService) {}
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      // try verify the role
      const role: PortalUserRoleType = next.data.role;
      if (role !== undefined && this.auth.hasRole(role)) {
        return true;
      }
    } catch (err) {
      // ignore
    }

    // await this.logout(state);
    this.router.navigate(['/home/dashboard']);
    return false;
  }
}

export class RolePermissionInfo {
  constructor(section: PortalSectionType, action: PortalActionType) {
    this.section = section;
    this.action = action;
  }
  section: PortalSectionType;
  action: PortalActionType;
}
