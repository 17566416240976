<nz-layout style="min-height: 100%;">
  <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="triggerTemplate" [nzBreakpoint]="'lg'" [nzCollapsedWidth]="0" [nzWidth]="250">
    <div class="top-left">
      <div class="logo"></div>
      <span class="dev" style="position: absolute; top: 2px;" *ngIf="showDebugBanner">Development</span>
    </div>
    <ul nz-menu [nzTheme]="'dark'" [nzMode]="'inline'" [nzInlineCollapsed]="isCollapsed" class="menu">
      <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menus }"></ng-container>
      <ng-template #menuTpl let-menus>
        <ng-container *ngFor="let menu of menus">
          <ng-container *ngIf="menu.visible">
            <li nz-menu-item [nzPaddingLeft]="menu.level * 6" *ngIf="!menu.children" [nzDisabled]="menu.disabled" [nzSelected]="menu.selected">
              <a [routerLink]="menu.links[0]">
                <span title>
                  <nz-badge nzDot [nzShowDot]="menu.badge > 0" class='menu-badge'>
                    <i class="menuIcon" *ngIf="menu.icon" [className]="menu.icon"></i>
                    &nbsp;&nbsp;
                    <span>{{ menu.title }}</span>
                  </nz-badge>
                </span>
              </a>
            </li>
            <li nz-submenu [nzPaddingLeft]="menu.level * 6" *ngIf="menu.children && menu.children.length > 0" [nzOpen]="menu.open && !isCollapsed" (nzOpen)="menu.open = $event" [nzDisabled]="menu.disabled">
              <span title>
                <nz-badge nzDot [nzShowDot]="menu.badge > 0" class='menu-badge'>
                  <i *ngIf="menu.icon" [className]="menu.icon"></i>&nbsp;&nbsp;
                  <span>{{ menu.title }}</span>
                </nz-badge>
              </span>
              <ul>
                <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menu.children }"></ng-container>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ng-template>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header class="container-fluid" style="background: #fff; border-bottom: 1px solid rgb(218, 225, 231);">
      <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'" (click)="isCollapsed = !isCollapsed"></i>
      <p class="hello">Management Portal</p>
      <p class="content-link">
        <a (click)="logout()"> <i class="fas fa-sign-out-alt"></i><span>Sign Out</span> </a>
      </p>
    </nz-header>
    <nz-content class="container-fluid">
      <ng-container *ngIf="!customBreadcrumbTemplate" [ngTemplateOutlet]="breadcrumbTemplate"></ng-container>
      <ng-container *ngIf="customBreadcrumbTemplate" [ngTemplateOutlet]="customBreadcrumbTemplate" [ngTemplateOutletContext]="{breadcrumbTemplate:breadcrumbTemplate}"></ng-container>
      <ng-template #breadcrumbTemplate>
        <nz-breadcrumb style="margin: 16px 0;" [nzSeparator]="iconTemplate">
          <nz-breadcrumb-item><a routerLink="/dashboard">Home</a></nz-breadcrumb-item>
          <ng-container *ngFor="let crumb of crumbs">
            <nz-breadcrumb-item *ngIf="crumb.route"><a [routerLink]="crumb.route">{{ crumb.title }}</a></nz-breadcrumb-item>
            <nz-breadcrumb-item *ngIf="!crumb.route">{{ crumb.title }}</nz-breadcrumb-item>
          </ng-container>
        </nz-breadcrumb>
      </ng-template>
      <ng-template #iconTemplate><i class="fas fa-angle-right"></i></ng-template>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
      <nz-back-top></nz-back-top>
    </nz-content>
    <nz-footer class="copyright">
      <!-- <p><span>Copyright&nbsp;&copy;&nbsp;{{today | date:'yyyy'}}</span>&nbsp;
    				<span><a href="https://www.86degrees.com" target="_blank">EightySix°</a></span>&nbsp;<span>All rights reserved.</span><span><a target="_blank" routerLink="/docs/terms">&nbsp;&nbsp;Terms &amp; Conditions</a></span><span class='dev' *ngIf='!isProduction'>Development</span>
    			</p> -->
      <p class="build">
        Build date: {{ version.buildDate }}
        <span class="dev" *ngIf="showDebugBanner">Development</span>
      </p>
    </nz-footer>
  </nz-layout>
</nz-layout>
<ng-template #trigger>
  <i nz-icon nzType="up"></i>
</ng-template>