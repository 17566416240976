import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppEvents } from 'src/app/utils/app-events';
import { AppStorage } from 'src/app/utils/app-storage';
import { CommonUtils } from 'src/app/utils/common-utils';
import {
  AuthService,
  LoginRequest,
  LoginResponse,
  LogoutRequest,
  PermissionActionType,
  PermissionSectionType,
  PortalOtherPermission,
  PortalUserRoleType,
  RefreshTokenRequest,
  RefreshTokenResponse,
} from './data-service.generated';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class UserAuthService {
  private authT: string = null;
  private refreshT: string = null;
  private loggedIn = false;
  private isAdmin = false;
  private userName: string = null;

  constructor(
    private authService: AuthService,
    private ev: AppEvents,
    private storage: AppStorage,
    private router: Router
  ) {}

  public async init() {
    this.authT = await this.storage.getAuthToken();
    this.refreshT = await this.storage.getRefreshToken();
    this.isAdmin = await this.storage.getIsAdministrator();
    this.userName = await this.storage.getName();
    this.loggedIn = !CommonUtils.isNullOrWhiteSpace(this.authT);
  }

  public get authToken() {
    return this.authT;
  }

  private setAuthToken(value: string) {
    this.loggedIn = !CommonUtils.isNullOrWhiteSpace(value);
    this.authT = value;
    // this.ev.authTokenChanged.next(value);
    return this.storage.setAuthToken(value);
  }

  public get refreshToken() {
    return this.refreshT;
  }

  private setRefreshToken(value: string) {
    this.refreshT = value;
    return this.storage.setRefreshToken(value);
  }

  get isAdministrator(): boolean {
    return this.isAdmin;
  }

  private setIsAdministrator(value: boolean) {
    this.isAdmin = value;
    return this.storage.setIsAdministrator(value);
  }
  private set rememberMe(value: boolean) {
    localStorage['rememberMe'] = value;
  }

  public get isLoggedIn() {
    return this.loggedIn;
  }

  public get name(): string {
    return this.userName;
  }

  public setName(value: string) {
    this.userName = value;
    return this.storage.setName(value);
  }

  /** Check if token (JWT) has expired */
  public isTokenExpired(token: string): boolean {
    return helper.isTokenExpired(token);
  }

  public hasSectionPermission(section: PermissionSectionType, action: PermissionActionType) {
    try {
      const token = helper.decodeToken(this.authToken);
      const permissions = JSON.parse(token.Permissions);
      return permissions.SectionPermissions[section] && permissions.SectionPermissions[section].includes(action);
    } catch (err) {
      // if there's some weirdness in the token log them out to fix it
      return false;
    }
  }

  public hasAnySectionPermission(section: PermissionSectionType, actions: PermissionActionType[]) {
    try {
      const token = helper.decodeToken(this.authToken);
      const permissions = JSON.parse(token.Permissions);

      if (permissions.sectionPermissions !== null && permissions.sectionPermissions !== undefined) {
        this.logout();
      }
      return permissions[section] && permissions[section].some((x: any) => actions.includes(x));
    } catch (err) {
      // if there's some weirdness in the token log them out to fix it
      this.logout();
      return false;
    }
  }

  public hasOtherPermission(permission: PortalOtherPermission) {
    try {
      const token = helper.decodeToken(this.authToken);
      const permissions = JSON.parse(token.Permissions);
      if (permissions.sectionPermissions !== null && permissions.sectionPermissions !== undefined) {
        this.logout();
      }
      return permissions.OtherPermissions.includes(permission);
    } catch (err) {
      // if there's some weirdness in the token log them out to fix it
      this.logout();
      return false;
    }
  }

  public hasRole(role: PortalUserRoleType) {
    try {
      const token = helper.decodeToken(this.authToken);
      const roles: PortalUserRoleType[] = JSON.parse(token.Roles);
      return roles.includes(role);
    } catch (err) {
      // if there's some weirdness in the token log them out to fix it
      return false;
    }
  }

  /**
   * Login and receive auth tokens.
   */
  public async login(email: string, password: string, rememberMe: boolean) {
    const request = new LoginRequest();
    request.email = email;
    request.password = password;

    await this.storage.setRememberMe(rememberMe);
    const response = await this.authService.login(request).toPromise();
    if (response && response.wasSuccessful) {
      if (!response.lockedOut) {
        await this.completeLogin(response);
      }
    }

    return response;
  }

  /**
   * Wrap up the login process (store data).
   */
  public async completeLogin(response: LoginResponse) {
    await this.setAuthToken(response.token);
    await this.setRefreshToken(response.refreshToken);
    await this.setName(response.name);
    await this.setIsAdministrator(response.administrator);

    await this.storage.setAppUrl(response.siteUrl);
  }

  /**
   * Logout this user and clear state.
   */
  public async logout(route: boolean = true) {
    const request = new LogoutRequest();
    request.refreshToken = this.refreshToken;

    await this.authService.logout(request).toPromise();
    await this.clearState();

    if (route) {
      await this.router.navigate(['auth/login']); // redirect to login page
    }
  }

  private async clearState() {
    await this.setAuthToken('');
    await this.setRefreshToken('');

    await this.setName('');
    await this.setIsAdministrator(false);
    await this.storage.setRememberMe(false);
  }

  /**
   * Make a service call to refresh the auth token.
   */
  public refreshAuthToken(): Observable<RefreshTokenResponse> {
    const request = new RefreshTokenRequest();
    request.refreshToken = this.refreshToken;
    request.token = this.authToken;

    return this.authService.refreshToken(request).pipe(
      switchMap(async (response) => {
        if (response.wasSuccessful) {
          await this.setAuthToken(response.token);
          await this.setRefreshToken(response.refreshToken);
          await this.setName(response.name);
          // this.refreshToken = response.refreshToken;
          // this.authToken = response.token;
          // this.name = response.name;
        } else {
          await this.setAuthToken('');
          await this.setRefreshToken('');
          await this.setName('');
          // this.refreshToken = '';
          // this.authToken = '';
          // this.name = '';
        }

        return response;
      })
    );
  }
}
